import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "auth",
    loadChildren: () =>
      import("./authentication/auth/auth.module").then((m) => m.AuthModule),
  },
  // {
  //   path: "",
  //   loadChildren: () =>
  //     import("./layout/main-page/main-page.module").then(
  //       (m) => m.MainPageModule
  //     ),
  // },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
