import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExitInfoService {

  allEmpFeedbackStatusUrl = `${environment.baseUrl}/edm/api/exit_info_all_employee_feedback_status/`;
  updateHrFeedbackUrl = `${environment.baseUrl}/edm/api/exit_info_hr_feedback`;
  updateManagerFeedbackUrl = `${environment.baseUrl}/edm/api/exit_info_manager_feedback`;
  updateAccountantFeedbackUrl = `${environment.baseUrl}/edm/api/exit_info_accountant_feedback`;
  updateItFeedbackUrl = `${environment.baseUrl}/edm/api/exit_info_it_feedback`;
  createExitInfoDetailsUrl = `${environment.baseUrl}/edm/api/exit_info_details_create`;
  // /500
  updateExitInfoDetailsUrl = `${environment.baseUrl}/edm/api/exit_info_details_update`;
  // /500
  getExitInfoDetailsUrl = `${environment.baseUrl}/edm/api/exit_info_details_status`;
  // /500
  getExitInfoSingleEmpFbStatusUrl = `${environment.baseUrl}/edm/api/exit_info_employee_feedback_status`;
  // /500
  resignUrl = `${environment.baseUrl}/edm/api/resign/`;
  empResignStatusUrl = `${environment.baseUrl}/edm/api/resigndata/`;
  empResignWithdrawUrl = `${environment.baseUrl}/edm/api/resigwithdraw/`;

  constructor(private http:HttpClient) { }

  getRole() {
    return localStorage.getItem('role');
  }

  getExitInfoSingleEmpFbStatus(userId:any){
    return this.http.get(`${this.getExitInfoSingleEmpFbStatusUrl}/${userId}/`);
  }

  getAllEmpFeedbackStatus() {
    return this.http.get(this.allEmpFeedbackStatusUrl);
  }

  updateHrFeedback(userId:any, data:any){
    return this.http.put(`${this.updateHrFeedbackUrl}/${userId}/`,data);
  }

  updateManagerFeedback(userId:any, data:any){
    return this.http.put(`${this.updateManagerFeedbackUrl}/${userId}/`,data);
  }

  updateAccountantFeedback(userId:any, data:any){
    return this.http.put(`${this.updateAccountantFeedbackUrl}/${userId}/`,data);
  }

  updateItFeedback(userId:any, data:any){
    return this.http.put(`${this.updateItFeedbackUrl}/${userId}/`,data);
  }

  // createExitInfoDetails(userId:any, data:any){
  //   return this.http.post(`${this.createExitInfoDetailsUrl}/${userId}/`, data);
  // }

  updateExitInfoDetails(userId:any, data:any){
    return this.http.put(`${this.updateExitInfoDetailsUrl}/${userId}/`, data);
  }

  getExitInfoDetails(userId:any){
    return this.http.get(`${this.getExitInfoDetailsUrl}/${userId}`);
  }

  getResignData(){
    return this.http.get(`${this.resignUrl}`);
  }

  postResignData(email_content:string){
    return this.http.post(`${this.resignUrl}`, email_content);
  }

  getEmpResignStatus(){
    return this.http.get(`${this.empResignStatusUrl}`);
  }

  withdrawEmpResign(){
    return this.http.delete(`${this.empResignWithdrawUrl}`);
  }
}
