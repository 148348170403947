<button class="close-btn" mat-icon-button [mat-dialog-close]="true">
	<i class="fa-solid fa-xmark"></i>
</button>

<div class="nomination-banner">
	<img src="../../../../assets/images/star-performer-medal.svg" alt="" />
	<div class="text-wrapper">
		<p class="sub-text">It's time to appreciate your amazing efforts!</p>
		<p class="sub-text2">Nominate</p>
		<p class="text-main">Star Performers of the Month</p>
		<p class="sub-text2">for <strong>{{data.month}} {{data.year}}</strong></p>

	</div>
	<button mat-raised-button mat-dialog-close [routerLink]="['star-performer/nominate']">Nominate NOW!</button>
	<p>
		Active from <strong>{{ data.from_date | date : "dd MMMM" }}</strong> to
		<strong>{{ data.to_date | date : "dd MMMM" }}</strong>
	</p>
</div>
