import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of, Subject } from "rxjs";
import { FormStateService } from "src/app/pages/exit-info/form-state.service";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class NavbarService {
	apiUrl = environment.baseUrl;

	statusMsg = [
		{
			status:'APPROVED',
			route:'auth/profile',
			text:'Profile'
			},
		{
		status:'NOT SUBMITTED',
		route:'auth/add-profile/add',
		text:'Add Profile'
		},
		{
		status:'NOT APPROVED',
		route:'auth/add-profile/edit',
		text:'Edit Profile'
		},
		
];
 

userProfileStatus:any = this.statusMsg[0];
	hidenavbar: boolean = false;
	hidenavbars = new Subject<boolean>();
	private profilePicSource = new BehaviorSubject<string | null>(null);
	profilePic$ = this.profilePicSource.asObservable();

	private exitInfoTabVisibleSubject = new BehaviorSubject<boolean>(true);
	exitInfoTabVisible$ = this.exitInfoTabVisibleSubject.asObservable();

	private readonly STORAGE_KEY = 'exitInfoTabVisible';

	constructor(private formStateService: FormStateService,private http:HttpClient) {
		const storedValue = localStorage.getItem('exitInfoTabVisible');
		if (storedValue) {
			this.exitInfoTabVisibleSubject.next(JSON.parse(storedValue));
		}
	}

	setProfilePic(profilePic: string | null) {
		this.profilePicSource.next(profilePic);
	}

	updateExitInfoTabVisibility(visible: boolean) {
		localStorage.setItem(this.STORAGE_KEY, JSON.stringify(visible));
		this.formStateService.formSubmitted$.subscribe((formSubmitted) => {
			this.exitInfoTabVisibleSubject.next(visible || formSubmitted);
		});
	}

	getUserProfileInfo(){
		return this.http.get(`${this.apiUrl}/edm/api/edm-form_status/`);
	}

	updateUseProfileStatus(value:string){
		this.userProfileStatus = this.statusMsg.filter(item=>item.status === value)[0];
	}

	getResign(){
		return this.http.get(`${this.apiUrl}/edm/api/get-resign`);
	}
}
