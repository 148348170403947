import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { NavbarService } from '../layout/navbar/navbar.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {
  constructor(private router :Router,private _navbarService:NavbarService){}
  ngOnInit(): void {
    this._navbarService.hidenavbars.next(true);
  }

}
