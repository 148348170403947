<div class="modal_content">
    <div class="modal-header timesheetdeilete-modal-header">
        <p class="">Are you Sure?</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body timesheetdelete-modal-body">
    
        <p class="para">Do you want to logout ?</p>
        <div class="timesheetdelete-modal-footer">
            <button type="button" class="cancel-btn" (click)="dismiss()">Cancel</button>
            <button type="button" class="confirm-btn" (click)="logout()">Logout</button>
        </div>
    </div>
</div>