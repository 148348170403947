import { SocialAuthService } from "@abacritt/angularx-social-login";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/authentication/auth/auth.service";

@Component({
  selector: "app-logout-confirm-popup",
  templateUrl: "./logout-confirm-popup.component.html",
  styleUrls: ["./logout-confirm-popup.component.scss"],
})
export class LogoutConfirmPopupComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private router: Router,
  ) {}
  dismiss() {
    this.activeModal.dismiss("cancel");
  }
  logout() {
    this.authService.logout();
    this.router.navigate(["/auth/login"]).then(() => {});
    this.activeModal.close("logout");
  }
}
