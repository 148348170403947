import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  resourcesUrl = `${environment.baseUrl}/resource/api/resource_list/`;
  getDatasUrl = `${environment.baseUrl}/resource/api/get_datas/`;
  updateResourceUrl = `${environment.baseUrl}/resource/api/resource_list_change`;

  constructor(private http:HttpClient) { }

  isManagerLoggedIn(){
    return (localStorage.getItem('role') === 'manager');
  }

  getResourceList(){
    return this.http.get(this.resourcesUrl);
  }

  getAllData(){
    return this.http.get(this.getDatasUrl);
  }

  addResource(data:any){
    return this.http.post(this.resourcesUrl,data);
  }

  updateResource(data:any, id:number){
    return this.http.put(`${this.updateResourceUrl}/${id}/`,data);
  }
}
