export const environment = {
	//  baseUrl: "https://projects.pacewisdom.in",
	// baseUrl:"https://projects-timesheet-dev.pacewisdom.in",
	baseUrl: "https://projects-uat.pacewisdom.in",
	// baseUrl:'https://05ktkzzq-8000.inc1.devtunnels.ms',
	baseUrl_2:"https://demo2.pacewisdom.in/api",
	googleClientId:
		"497461030906-evr597jegqvsaturbseis45n8abv67ka.apps.googleusercontent.com",
	CALANDER_CLIENT_ID: "0dff3a4b-4027-4526-8922-a71fdd3f9f09",
	microsoftClientId: "a0ea4061-3a36-4b74-a0e2-46cc41982221",
	tenantId: "887aa0fa-80f4-4405-b6f0-91b25cfe11c0",
};
