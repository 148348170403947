import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/authentication/auth/auth.service";
import { NavbarService } from "./navbar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LogoutConfirmPopupComponent } from "./logout-confirm-popup/logout-confirm-popup.component";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { ResourcesService } from "src/app/pages/resources/resources.service";
import { ExitInfoService } from "src/app/pages/exit-info/exit-info.service";
import { FormStateService } from "src/app/pages/exit-info/form-state.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit,OnDestroy {
	isMenuCollapsed = true;
	photoUrl!: string | null;
	username!: string | null;
	logoutIcon = faRightFromBracket;
	managerLoggedIn: boolean = false;
	isAuthenticated: boolean = false;
	userRole!: string | null;
	allEmpList: any[] = [];
	localUserId!: string | null;
	filteredFbEmployees: any[] = [];
	filteredFbNotCompletedEmployees: any[] = [];
	exitInfoTabVisible: boolean = true;
	formSubmitted: any = false;
	allSubscriptions: Subscription[] = [];

	

	

	constructor(
		private router: Router,
		private authService: AuthService,
		public navbarService: NavbarService,
		private modalService: NgbModal,
		private resourcesService: ResourcesService,
		private exitInfoService: ExitInfoService,
		private formStateService: FormStateService,
	) { 
		this.navbarService.getUserProfileInfo().subscribe({
			next:(val:any)=>{
				this.navbarService.updateUseProfileStatus(val.status);
			}
		})
	}

	ngOnInit(): void {
		this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
			this.isAuthenticated = isAuthenticated;
		});
		


		this.managerLoggedIn = this.resourcesService.isManagerLoggedIn();		

		this.navbarService.profilePic$.subscribe((profilePic) => {
			if (profilePic) {
				this.photoUrl = profilePic;
			}
		});

		this.formStateService.formSubmitted$.subscribe(formSubmitted => {
			this.formSubmitted = formSubmitted; // Update formSubmitted in the NavbarComponent
		});

		this.navbarService.exitInfoTabVisible$.subscribe(visible => {
			this.exitInfoTabVisible = visible;
		});

		this.username = localStorage.getItem("username");
		this.userRole = localStorage.getItem("role");
		this.localUserId = localStorage.getItem("P1");

		if (this.userRole === "accounts") {
			this.accFbEmployees();
		} else if (this.userRole === "manager") {
			this.managerFbEmployees();
		} else if (this.userRole === "it") {
			this.itFbEmployees();
		} else if (this.userRole === "hr") {
			this.hrFbEmployees();
		} else if (this.userRole === "user") {
			this.employeeStatus();
		}
	}

	employeeStatus() {
		this.allSubscriptions.push(this.exitInfoService.getEmpResignStatus().subscribe({
			next: (res: any) => {
				if (res && res.status) {
					const status = res.status;
					const showExitInfoTab = status !== 'No Data Available';
					this.navbarService.updateExitInfoTabVisibility(showExitInfoTab);
				}
			}
		}));
	
		this.allSubscriptions.push(this.exitInfoService.getResignData().subscribe((res: any) => {			
			if (res && res.status === false) {
				this.navbarService.updateExitInfoTabVisibility(true);
			}
		}));
	}

	isExitInfoTabVisibleForUser(): boolean {
		return (this.userRole === "user" && this.exitInfoTabVisible);
	}

	isExitInfoTabVisibleForManager(): boolean {
		return (
			this.userRole === "manager" &&
			this.filteredFbEmployees.some((emp) => !emp.exit_info_process_completed)
		);
	}

	isExitInfoTabVisibleForAcc(): boolean {
		return (
			this.userRole === "accounts" &&
			this.filteredFbEmployees.some((emp) => !emp.exit_info_process_completed)
		);
	}

	isExitInfoTabVisibleForIt(): boolean {
		return (
			this.userRole === "it" &&
			this.filteredFbEmployees.some((emp) => !emp.exit_info_process_completed)
		);
	}

	isExitInfoTabVisibleForHr(): boolean {
		return (
			this.userRole === "hr" &&
			this.filteredFbEmployees.some((emp) => !emp.exit_info_process_completed)
		);
	}

	accFbEmployees() {
		this.allSubscriptions.push(
			this.exitInfoService.getAllEmpFeedbackStatus().subscribe((res: any) => {
				if (this.localUserId !== null) {
					this.filteredFbEmployees = res.filter((employee: any) => {
						return (
							String(employee.feedback_status.accounts_feedback.user_id) ===
							this.localUserId
						);
					});
	
					this.filteredFbEmployees = this.filteredFbEmployees.map((item) => ({
						...item.employee_details,
						...item.feedback_status,
					}));
	
					// Filter based on exit_info_process_completed === false
					this.filteredFbNotCompletedEmployees = this.filteredFbEmployees.filter(
						(employee) => !employee.accounts_feedback.completed
					);
				}
			})
		);
	}

	managerFbEmployees() {
		this.allSubscriptions.push(
			this.exitInfoService.getAllEmpFeedbackStatus().subscribe((res: any) => {
				if (this.localUserId !== null) {
					this.filteredFbEmployees = res.filter((employee: any) => {
						return (
							String(employee.feedback_status.manager_feedback.user_id) ===
							this.localUserId
						);
					});
	
					this.filteredFbEmployees = this.filteredFbEmployees.map((item) => ({
						...item.employee_details,
						...item.feedback_status,
					}));
	
					this.filteredFbNotCompletedEmployees = this.filteredFbEmployees.filter(
						(employee) => !employee.manager_feedback.completed
					);
				}
			})
		);
	}

	itFbEmployees() {
		this.allSubscriptions.push(
			this.exitInfoService.getAllEmpFeedbackStatus().subscribe((res: any) => {
				if (this.localUserId !== null) {
					this.filteredFbEmployees = res.filter((employee: any) => {
						return (
							String(employee.feedback_status.it_feedback.user_id) ===
							this.localUserId
						);
					});
	
					this.filteredFbEmployees = this.filteredFbEmployees.map((item) => ({
						...item.employee_details,
						...item.feedback_status,
					}));
	
					// Filter based on exit_info_process_completed === false
					this.filteredFbNotCompletedEmployees = this.filteredFbEmployees.filter(
						(employee) => !employee.it_feedback.completed
					);
				}
			})
		);		
	}

	hrFbEmployees() {
		this.allSubscriptions.push(
			this.exitInfoService.getAllEmpFeedbackStatus().subscribe((res: any) => {
				if (this.localUserId !== null) {
					this.filteredFbEmployees = res.filter((employee: any) => {
						return (
							String(employee.feedback_status.hr_feedback.user_id) ===
							this.localUserId
						);
					});
	
					this.filteredFbEmployees = this.filteredFbEmployees.map((item) => ({
						...item.employee_details,
						...item.feedback_status,
					}));
	
					// Filter based on exit_info_process_completed === false
					this.filteredFbNotCompletedEmployees = this.filteredFbEmployees.filter(
						(employee) => !employee.hr_feedback.completed
					);
				}
			})
		);
	}

	getInitial(name: any): string {
		if (name) {
			if (!name || name.trim().length === 0) {
				return "";
			}
			let names = name.split(" ").filter(Boolean);
			if (names.length >= 2) {
				return (
					names[0][0]?.toUpperCase() +
					" " +
					names[names.length - 1][0]?.toUpperCase()
				);
			} else {
				return names[0][0]?.toUpperCase();
			}
		}
		return name;
	}

	openLogoutConfirmPopup() {
		this.modalService.open(LogoutConfirmPopupComponent);
	}

	ngOnDestroy(): void {
		this.allSubscriptions.forEach(subscription => subscription.unsubscribe());
	}

	onResign(){
		this.navbarService.getResign().subscribe({
			next:(val)=>{
				this.router.navigate(['exit_info/resign']);
			},error:(err)=>{
				
			}
		})

	}
}
