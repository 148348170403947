import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, map, single, tap } from 'rxjs';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { environment } from 'src/environments/environment';
import { StarPerformerBannerComponent } from './star-performer-banner/star-performer-banner.component';

@Injectable({
  providedIn: 'root'
})
export class StarPerformerService {
  private apiUrl = environment.baseUrl;
  statusLoading$ = new BehaviorSubject<any>(null);
  formStatus!: {
    status?: boolean,
    month?: string,
    year?: number,
    from_date?: string,
    to_date?: string;
  }
    
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  constructor(private http: HttpClient, private dialog: MatDialog, private navbar:NavbarService) {
    navbar.hidenavbars.pipe(single(res => res == true)).subscribe( res=>{
      this.getStatus();
    })
  }

  getStatus() {
    this.statusLoading$.next(true);
    return this.http.get(`${this.apiUrl}/edm/api/starperformer/status/`).subscribe((res: any) => {
      this.formStatus = Object.assign(res, { month: this.monthNames[res.month - 1] });
      this.handleBannerDisplay();
      this.statusLoading$.next(false);
    });
  }

  getFormConstructionData(nomineeType: string) {
    return this.http.get(`${this.apiUrl}/edm/api/starperformerform?nomination_type=${nomineeType}`).pipe(map((res: any) => {
      res.user_list.map((emp: any) => {
        emp.label = `${emp.name} (${emp.emp_id})`;
      });
      return res;
    }));
  }

  getProjectList(emp_id: string) {
    return this.http.get(`${this.apiUrl}/timesheet/api/project/list/${emp_id}/true/`);
  }

  nominate(payload: any) {
    let data = this.prepareFormSubmissionData(payload);
    return this.http.post(`${this.apiUrl}/edm/api/starperformer/nominate/`, data).pipe(tap(res => {
      localStorage.setItem('nominationSubmissionDate', new Date().toString());
    }));
  }

  getWinnersList() {
    return this.http.get(`${this.apiUrl}/edm/api/starperformer/winnerslist/`).pipe(
      map((res: any) => {
        return this.prepareWinnersData(res.data);
      }));
  }

  handleBannerDisplay() {
    if (this.formStatus.status) {
      const nominationSubmissionDate = localStorage.getItem('nominationSubmissionDate');
      if (nominationSubmissionDate) {
        const currentMonth = new Date().getMonth();
        const nominationSubmissionMonth = new Date().getMonth();
        if (nominationSubmissionMonth >= currentMonth)
          return;

      }
      const bannerDate = localStorage.getItem('bannerDisplayDate');
      if (bannerDate) {
        const displayDate = new Date(bannerDate).getDate();
        const currentDate = new Date().getDate();

        if (displayDate < currentDate) {
          this.displayBannerDialog();
        }
      }
      else this.displayBannerDialog();
    }
  }

  displayBannerDialog() {
    setTimeout(() => {
      this.dialog.open(StarPerformerBannerComponent, {
        data: this.formStatus
      });
      localStorage.setItem('bannerDisplayDate', new Date().toString());
    },500)
    
  }


  prepareFormSubmissionData(data: any) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      formData.append(key, value ?? '');
    });
    return formData;
  }

  prepareWinnersData(dataSouce: any[]): Map<string, any[]> {

    //Sort Data by Year and Month
    dataSouce = dataSouce.sort((a: any, b: any) => {
      if (a.year !== b.year)
        return b.year - a.year;
      return b.month - a.month;
    });
    const dataRecord: any = {};
    dataSouce.forEach((data: any) => {
      data.date = this.monthNames[data.month - 1] + ' ' + data.year;
      dataRecord[data.date] = [...dataRecord[data.date] ?? [], data];
    });

    return dataRecord;
  }
}
