import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, finalize, Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { NotifierService } from "src/app/Toaster/notifier.service";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private router:Router,private toastr:NotifierService,private authService:AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if ( request.url === `${environment.baseUrl}/timesheet/api/dj-rest-auth/google/` 
    ) {
      return next.handle(request); // Skip interception and pass the request as is.
    }    
    if (request.url === `${environment.baseUrl}/timesheet/api/microsoft/callback/verify/`) {
      return next.handle(request); // Skip interception and pass the request as is.
    }
    if (request.url === `${environment.baseUrl}/timesheet/api/forgot_password/` ) {
      return next.handle(request); // Skip interception and pass the request as is.
    }
    if (request.url === `${environment.baseUrl}/timesheet/api/reset_password/` ) {
      return next.handle(request); // Skip interception and pass the request as is.
    }
    if (request.url.includes(`${environment.baseUrl}/edm/api/Personal_edm_info`) && request.url.includes('PW')) {
      return next.handle(request);
    }

    let modifiedReq = request.clone({
      setHeaders: {
        // "Content-Type": "application/json",
        
        Authorization: `Bearer ${localStorage.getItem("P2")}`,
      },
    });

    // return next.handle(modifiedReq).pipe(finalize(() => {}));
    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle token expired or invalid error
          this.toastr.showError("ERROR","Invalid Email or Password."); // Show error message
          this.authService.logout();
          localStorage.setItem('intendedUrl', window.location.href.replace(window.location.origin, ''));
          this.router.navigate(['/auth/login']);
        }
        return throwError(error); // Re-throw the error to propagate it further
      }),
      finalize(() => {})
    );
  }
}
