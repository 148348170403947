import { ChangeDetectorRef, Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NavbarService } from "./layout/navbar/navbar.service";
import { StarPerformerService } from "./pages/star-performer/star-performer.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "timeSheetManagement";

  hidenavbars: boolean = false;
  isLoginPage: boolean = false;

  constructor(
    public navbarService: NavbarService,
    private _navbarservice: NavbarService,
    private cdr: ChangeDetectorRef,
    private router: Router,
		private starPerformer: StarPerformerService  
  ) {
    this.hidenavbars = true;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url.includes("login")|| event.url.includes("PW");
      }
    });
  }
  ngOnInit() {
    this._navbarservice.hidenavbars.subscribe((res: any) => {
      this.hidenavbars = res;
      this.cdr.detectChanges();
      
      // To display Star performer banner when form is active
      if(res==true)
		    this.starPerformer.getStatus();

    });
  }
}
