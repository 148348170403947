import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StarPerformerService } from '../star-performer.service';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';

@Component({
  selector: 'app-star-performer-banner',
  templateUrl: './star-performer-banner.component.html',
  styleUrls: ['./star-performer-banner.component.scss']
})
export class StarPerformerBannerComponent implements OnInit, OnDestroy {
  constructor(private service: StarPerformerService,		private _navbarService: NavbarService,    @Inject(MAT_DIALOG_DATA) public data: {
    status: boolean,
    month: string,
    year: number,
    from_date: string,
    to_date: string;
  }) { }

  ngOnInit(): void {
    this._navbarService.hidenavbars.next(true);

    document.body.classList.add('star-performer-banner-display');
  }
  ngOnDestroy(): void {
    document.body.classList.remove('star-performer-banner-display');
  }

}
